<template>
  <div>
    <v-btn icon exact v-bind="buttonProps" @click="shareHandler">
      <v-icon>mdi-share-variant</v-icon>
    </v-btn>
    <v-snackbar
      v-model="snackbar.show"
      :timeout="5000"
      :color="snackbar.color"
      bottom
    >
      {{ $t(snackbar.text) }}
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "ShareButton",
  props: {
    link: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    buttonProps: {
      type: Object
    }
  },
  data() {
    return {
      baseURL: process.env.VUE_APP_BASE_URL,
      snackbar: {
        show: false,
        color: "success",
        text: ""
      }
    };
  },
  methods: {
    shareHandler() {
      const relativePath = this.$router.resolve(this.$props.link).href;
      const absolutePath = `${this.baseURL}${relativePath}`;

      if (navigator.share) {
        navigator
          .share({
            title: this.$props.title,
            text: this.$props.text,
            url: absolutePath
          })
          .catch(error => {
            console.error("Failed to share:", error);
            this.copyToClipboard(absolutePath);
          });
      } else {
        this.copyToClipboard(absolutePath);
      }
    },
    copyToClipboard(content) {
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(content)
          .then(() => {
            this.snackbar.color = "success";
            this.snackbar.text = "shareButton.clipboard.success";
            this.snackbar.show = true;
          })
          .catch(error => {
            console.error("Failed to copy: ", error);
            this.snackbar.color = "error";
            this.snackbar.text = "shareButton.clipboard.error";
            this.snackbar.show = true;
          });
      } else {
        console.error("Clipboard not supported.");
      }
    }
  }
};
</script>
